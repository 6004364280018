import React from 'react'
import { Container } from '@mui/material';
import Background from '../../Images/background.svg'


const DocMade = () => {
  return (
    <div style={{ marginTop: '35px', marginBottom: '35px' }}>
    <Container maxWidth="lg" sx={{backgroundImage: Background}}>
        
        </Container></div>
  )
}

export default DocMade